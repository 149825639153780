/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

.center {
    width: 1200px;
    margin: 0 auto;
}
.header {
    width: 100%;
    background: #fff;
    position: relative;
    overflow: hidden;
    .toptext {
        width: 100%;
        background: #eee;
        height: 30px;
        p {
            color: #7b7b7b;
            font-size: 14px;
            line-height: 30px;
        }
    }
    .head_top {
        width: 100%;
        padding: 20px 0;
        .fl {
            img {
                height: 70px;
                float: left;
            }
            p {
                float: left;
                margin-top: 12px;
                padding-left: 20px;
                margin-left: 18px;
                border-left: 1px solid #ccc;
                span {
                    font-size: 18px;
                    line-height: 140%;
                    color: #5a5a5a;
                    display: block;
                    font-weight: bold;
                    i {
                        font-style: normal;
                        font-size: 18px;
                        line-height: 140%;
                        color: #c40000;
                    }
                }
                small {
                    font-style: normal;
                    font-size: 18px;
                    line-height: 140%;
                    color: #5a5a5a;
                    display: block;
                    width: 325px;
                    float: left;
                }
            }
            span {
                float: left;
            }
        }
        .fr {
            img {
                float: left;
                width: 64px;
            }
            p {
                font-size: 12px;
                color: #5a5a5a;
                line-height: 140%;
                display: block;
                float: left;
                padding-top: 10px;
                padding-left: 5px;
                span {
                    font-size: 26px;
                    color: #5a5a5a;
                    font-family: Tahoma;
                    line-height: 139%;
                    display: block;
                    font-weight: bold;
                }
            }
        }
    }
}
.nav {
    background: #c40000;
    width: 100%;
    height: 45px;
    ul {
        li {
            float: left;
            color: #fff;
            width: 12.5%;
            height: 45px;
            a {
                width: 100%;
                height: 45px;
                background: #c40000;
                text-align: center;
                line-height: 45px;
                font-size: 15px;
                color: #fff;
                display: block;
                &:hover {
                    background: #2d2d2d;
                }
                &.active {
                    background: #2d2d2d;
                }
            }
        }
    }
}
.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 10000;
    float: none !important;
}


.banner_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 9;
    bottom: 10px;
    overflow: hidden;
    a {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        text-indent: -999px;
        overflow: hidden;
        margin: 0 3px;
        overflow: hidden;
        cursor: pointer;
        background: #eee;
        opacity: .4;
        margin: 0 5px;
        border: solid 2px #FFFFFF;
        &.in {
            background: #c40000;
            opacity: 1;
        }

    }
}
.pc_banner {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .banner {
        width: 100%;
        position: relative;
        ul {
            li {
                width: 100%;
                height: 600px;
                position: relative;
                img {
                    width: 1920px;
                    height: 600px;
                    position: relative;
                    left: 50%;
                    margin-left: -960px;
                }
            }
        }
    }
}
.m_banner {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: none;
    .mbanner {
        width: 100%;
        position: relative;
        ul {
            li {
                width: 100%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}



.m_banner {
    display: none;
}
.m_header {
    width: 100%;
    overflow: hidden;
    display: none;
    .m_head_content {
        height: .46rem;
        width: 100%;
        background: #c40000;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        box-shadow: 0px 5px 10px 1px #999;
        .m_head_logo {
            height: .46rem;
            float: left;
            margin-left: .05rem;
            img {
                max-height: 100%;
                float: left;
                height: 70px;
                margin-top: 5px;
            }
            p {
                float: left;
                margin-left: .05rem;
                strong {
                    font-size: .16rem;
                    line-height: .46rem;
                    display: block;
                }
            }
        }
        .menu {
            float: right;
            width: .46rem;
            height: .46rem;
            background: #c40000;
            i {
                color: #fff;
                font-size: 20px;
                text-align: center;
                line-height: .46rem;
                display: block;
            }
        }
    }   
}
.app_menu {
    width: 100%;
    background: rgba(0,0,0,.5);
    position: fixed;
    height: 100%;
    top: .46rem;
    display: none;
    z-index: 99;
    ul {
        background: rgba(0, 0, 0, .9);
        li {
            a {
                span {
                    font-size: 14px;
                    width: 100%;
                    height: 32px;
                    line-height: 32px;
                    color: #fff;
                    display: block;
                    padding-left: .15rem;
                    border-bottom: 1px solid #ccc;
                }
            }
        }
    }
}

.main {
    width: 100%;
    overflow: hidden;
}

.search {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
    .text {
        height: 42px;
        .fl {
            p {
                float: left;
                font-size: 12px;
                color: #666;
                line-height: 42px;
            }
            a {
                float: left;
                font-size: 12px;
                color: #666;
                line-height: 42px;
                margin-left: 20px;
                &:hover {
                    color: #c40000;
                    font-weight: bold;
                }
                &:active {
                    color: #c40000;
                    font-weight: bold;
                }
            }
        }
        .fr {
            width: 300px;
            border: 1px solid #eee;
            height: 42px;
            padding: 3px;
            .btn1 {
                border: none;
                height: 36px;
                outline: 0;
                width: 230px;
                padding-left: 10px;
            }
            .btn2 {
                border: none;
                outline: 0;
                height: 36px;
                width: 58px;
                background: #fff;
                color: #000;
            }
        }
    }
}


.i_box1 {
    width: 100%;
    padding-top: 40px;
}
.i_box1_content {
    .fl {
        width: 220px;
        background: #c40000;
        padding-bottom: 29px;
        h3 {
            width: 100%;
            line-height: 60px;
            font-size: 18px;
            font-weight: bold;
            display: block;
            color: #fff;
            text-align: center;
        }
        .pro_menu {
            width: 98%;
            margin: 0 auto;
            background: #fff;
            padding: 15px;
            ul {
                width: 100%;
                li {
                    padding: 0 10px;
                    padding-bottom: 10px;
                    border-top: 1px solid #ddd;
                    .yia {
                        height: 40px;
                        a {
                            line-height: 40px;
                            display: block;
                            text-align: left;
                            font-size: 15px;
                            color: #000;
                            font-weight: bold;
                            background: url(../img/ri.png) no-repeat right center;
                        }
                    }
                    .era {
                        a {
                            display: block;
                            line-height: 30px;
                            font-size: 15px;
                            color: #5a5a5a;
                        }
                    }
                    &:first-child {
                        border-top: none;
                    }
                }
            }
        }
        input {
            width: 160px;
            height: 34px;
            display: block;
            margin: 0 auto;
            border-radius: 3px;
            outline: 0;
        }
        .btn1 {
            margin-top: 40px;
            border: none;
            border: 1px solid #fff;
            background: #c40000;
            font-size: 15px;
            color: #fff;
            text-align: center;
            &::-webkit-input-placeholder{
                color: #fff;
            }
            &:focus {
                background: #666;
            }
        }
        .btn2 {
            border: none;
            margin-top: 10px;
            background: #fff;
            color: #c40000;
        }

    }
    .fr {
        width: 950px;
        padding: 40px;
        padding-bottom: 0;
        border: 1px solid #ccc;
        .list {
            ul {
                width: 109%;
                li {
                    float: left;
                    margin-right: 60px;
                    margin-bottom: 40px;
                    position: relative;
                    width: 250px;
                    .pic {
                        .imgauto {
                            width: 100%;
                            height: 250px;
                            img {
                                transform: scale(1);
                                transition: .5s;
                            }
                        }
                        span {
                            font-size: 14px;
                            color: #5a5a5a;
                            font-weight: bold;
                            display: block;
                            text-align: center;
                            margin-top: 10px;
                            &:hover {
                                color: #c40000;
                            }
                        }
                    }
                    .text {
                        display: none;
                        width: 100%;
                        height: 250px;
                        background: rgba(196,0,0,.7);
                        position: absolute;
                        top: 0;
                        left: 0;
                        a {
                            width: 100%;
                            height: 100%;
                            display: block;
                            img {
                                display: block;
                                margin: 100px auto;
                            }
                        }
                    }
                    &:hover {
                        .pic {
                            .imgauto {
                                img {
                                    transform: scale(1.1,1.1);
                                    transition: .5s;
                                }
                            }
                        }
                        .text {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
.box1_pro_tit {
    width: 100%;
    margin: 20px 0;
    display: none;
    ul {
        text-align: center;
        li {
            @include inline-block();
            a {
                background: #c40000;
                width: 100px;
                line-height: 42px;
                text-align: center;
                color: #fff;
                font-size: 14px;
                border-radius: 3px;
                margin: 0 5px;
                @include inline-block();
            }
        } 
    }
}

.i_box2 {
    width: 100%;
    height: 320px;
    margin-top: 50px;
    background: url(../img/bann.jpg) no-repeat center;
    .text {
        width: 480px;
        padding-top: 50px;
        p {
            color: #fff;
            font-size: 16px;
            letter-spacing: 2px;
            margin-top: 10px;
        }
        strong {
            height: 50px;
            margin-top: 10px;
            border: 1px solid #fff;
            color: #fff;
            width: 480px;
            font-size: 26px;
            letter-spacing: 5px;
            font-weight: normal;
            display: block;
            text-align: center;
            line-height: 50px;
        }
        p {
            span {
                font-size: 26px;
            }
            a {
                width: 120px;
                height: 36px;
                border: 1px solid #fff;
                color: #fff;
                font-size: 14px;
                text-align: center;
                line-height: 36px;
                float: right;
                &:hover {
                    background: #fff;
                    color: #c40000;
                }
            }
        }
    }
}
.i_box3 {
    width: 100%;
    padding-top: 40px;
}
.i_box3_content {
    width: 100%;
}
.box3_pic {
    width: 100%;
    img {
        display: block;
        margin: 0 auto;
    }
}
.box3_tit {
    p {
        font-size: 40px;
        color: #000;
        width: 100%;
        text-align: center;
        i {
            font-size: 64px;
            color: #c40000;
            font-weight: bold;
        }
        span {
            font-size: 46px;
            color: #c40000;
            font-weight: bold;
        }
    }
    small {
        text-align: center;
        display: block;
        font-size: 16px;
        margin-top: 10px;
        background: url(../img/xian.png) no-repeat center;
    }
    em {
        font-size: 16px;
        font-style: normal;
        line-height: 180%;
        display: block;
        text-align: center;
        color: #666;
        margin-top: 15px;
    }
}
.box3_list {
    width: 100%;
    padding-bottom: 40px;
    .item {
        width: 100%;
        margin-top: 50px;
        float: left;
        .mpic {
            display: none;
        }
        .pic {
            width: 55%;
            img {
                max-width: 100%;
                display: block;
            }
        }
        .text {
            width: 40%;
            .text_tit {
                height: 112px;
                background: url(../img/ysbg1.png) no-repeat left center;
                em {
                    width: 60px;
                    height: 60px;
                    background: #c40000;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 60px;
                    font-style: normal;
                    font-size: 32px;
                    display: block;
                    color: #fff;
                    float: left;
                    margin-left: -30px;
                    margin-top: 25px;
                }
                p {
                    padding-top: 33px;
                    padding-left: 60px;
                    strong {
                        font-size: 18px;
                        color: #000;
                        display: block;
                    }
                    i {
                        display: block;
                        font-style: normal;
                        font-size: 12px;
                        color: #999;
                    }
                }
            }
            .text_text {
                margin-top: 30px;
                span {
                    font-size: 14px;
                    color: #666;
                    line-height: 200%;
                    padding-left: 30px;
                    display: block;
                    background: url(../img/dian.png) no-repeat left 9px;
                    margin-bottom: 5px;
                }
                p {
                    margin-top: 30px;
                    width: 300px;
                    height: 40px;
                    background: url(../img/phone.png) no-repeat 10px center #c40000;
                    font-size: 14px;
                    color: #fff;
                    background-size: 10%;
                    padding-left: 50px;
                    line-height: 40px;
                    i {
                        font-style: normal;
                        font-size: 20px;
                        line-height: 40px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .item2 {
        padding-left: 5%;
    }
}
.mbox3pic {
    width: 100%;
    position: relative;
    .bd {
        width: 98%;
        margin: 0 1%;
        ul {
            li {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .prev {
        left: 14px;
        width: 60px;
        position: absolute;
        height: 111px;
        top: 40%;
        background: url(../img/lunbo_17.png) no-repeat;
        background-size: 100%;
    }
    .next {
        right: 14px;
        width: 60px;
        height: 111px;
        position: absolute;
        top: 40%;
        background: url(../img/lunbo_20.png) no-repeat;
        background-size: 100%;
    }
}

.i_box4 {
    width: 100%;
    padding: 50px 0;
    margin-top: 20px;
    background: url(../img/box4bg.jpg) no-repeat center;
}
.i_box4_content {
    width: 100%;
    .pic {
        width: 100%;
        margin-top: 20px;
    }
    .box4pic {
        width: 100%;
        position: relative;
        .bd {
            ul {
                li {
                    img {
                        width: 100%;
                        height: auto;;
                    }
                    span {
                        position: absolute;
                        bottom: 15px;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                        color: #fff;
                        width: 100%;
                        left: 0;
                    }
                }
            }
        }
        .prev {
            left: 14px;
            width: 43px;
            position: absolute;
            height: 43px;
            top: 42%;
            background: url(../img/le2.png) no-repeat;
            background-size: 100%;
        }
        .next {
            right: 14px;
            width: 43px;
            height: 43px;
            position: absolute;
            top: 42%;
            background: url(../img/ri2.png) no-repeat;
            background-size: 100%;
        }
    }
}
.box4_tit {
    p {
        font-size: 26px;
        color: #000;
        line-height: 180%;
        font-weight: bold;
        text-align: center;
        i {
            font-style: normal;
            color: #c40000;
        }
    }
    span {
        font-size: 14px;
        color: #999;
        text-align: center;
        line-height: 24px;
        display: block;
    }
}


.i_box5 {
    width: 100%;
    padding: 40px 0;
}
.i_box5_content {
    .fl {
        width: 580px;
       .mbox5pic {
            width: 100%;
            position: relative;
            .hd {
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 100%;
                z-index: 9;
                ul {
                    width: 100%;
                    text-align: center;
                    li {
                        width: 24px;
                        height: 24px;
                        border: 1px solid #fff;
                        color: #fff;
                        font-size: 12px;
                        line-height: 22px;
                        text-align: center;
                        border-radius: 50%;
                        @include inline-block();
                        &.on {
                            background: #fff;
                            color: #333;
                        }
                    }
                }
            }
            .bd {
                width: 100%;
                ul {
                    li {
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .prev {
                left: 14px;
                width: 43px;
                position: absolute;
                height: 43px;
                top: 50%;
                background: url(../img/le2.png) no-repeat;
                background-size: 100%;
            }
            .next {
                right: 14px;
                width: 43px;
                height: 43px;
                position: absolute;
                top: 50%;
                background: url(../img/ri2.png) no-repeat;
                background-size: 100%;
            }
        } 
    }
    .fr {
        width: 550px;
        h3 {
            font-size: 28px;
            color: #5a5a5a;
            margin-bottom: 30px;
        }
        p {
            font-size: 14px;
            line-height: 200%;
        }
        ul {
            width: 100%;
            margin-top: 30px;
            li {
                float: left;
                width: 66px;
                height: 66px;
                position: relative;
                margin-right: 30px;
                img {
                    width: 66px;
                    height: 66px;
                }
                .img1 {
                    position: absolute;
                    z-index: 9;
                }
                .img2 {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 0;
                }
                &:hover {
                    .img1 {
                        display: none;
                    }
                    .img2 {
                        height: 66px;
                        transition: .5s;
                        display: block;
                    }
                }
            }
        }
    }
}
.i_box6 {
    width: 100%;
    padding: 50px 0;
    margin-top: 20px;
    background: url(../img/box6bg.jpg) no-repeat center;
    ul {
        li {
            width: 16%;
            margin: 0 4%;
            float: left;
            span {
                font-size: 28px;
                color: #fff;
                text-align: center;
                display: block;
                i {
                    font-size: 40px;
                    font-style: normal;
                    color: #fff;
                    font-style: normal;
                }
            }
            p {
                font-size: 14px;
                color: #fff;
                display: block;
                text-align: center;
                margin-top: 15px;
            }
        }
    }
}

.i_box7 {
    width: 100%;
    margin-top: 50px;
}
.i_box7_content {
    width: 100%;
    margin-top: 40px;
    .fl {
        width: 220px;
        background: #eee;
        padding-bottom: 30px;
        h3 {
            width: 100%;
            line-height: 80px;
            font-size: 20px;
            color: #fff;
            text-align: center;
            display: block;
            background: #c40000;
        }
        ul {
            width: 80%;
            margin: 0 10%;
            li {
                width: 100%;
                height: 40px;
                display: block;
                background: #fff;
                margin-top: 16px;
                border: 1px solid #ccc;
                a {
                    display: block;
                    text-align: center;
                    line-height: 40px;
                    color: #333;
                }
                &:hover {
                    background: #c40000;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    .fr {
        width: 935px;
        h3 {
            font-style: normal;
            color: #c40000;
            font-size: 26px;
            line-height: 180%;
            font-weight: bold;
            text-align: center;
            display: none;
        }
        ul {
            width: 105%;
            li {
                float: left;
                margin-right: 22px;
                margin-bottom: 16px;
                position: relative;
                width: 296px;
                .imgauto {
                    width: 296px;
                    height: 185px;
                    img {
                        transform: scale(1);
                        transition: .5s;
                    }
                }
                .text {
                    display: none;
                    width: 100%;
                    height: 185px;
                    background: rgba(0, 0, 0, .7);
                    position: absolute;
                    top: 0;
                    left: 0;
                    a {
                        display: block;
                        text-align: center;
                        font-size: 14px;
                        color: #fff;
                        line-height: 185px;
                    }
                }
                &:hover {
                    .text {
                        display: block;
                    }
                    .imgauto {
                        img {
                            transform: scale(1.2,1.2);
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}
.i_box8 {
    width: 100%;
    background: url(../img/box8bg.jpg) no-repeat center;
    padding: 20px 0;
    margin-top: 30px;
}
.i_box8_content {
    width: 100%;
    .box8left {
        ul {
            li {
                width: 16.66%;
                float: left;
                img {
                    width: 95%;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}
.box9tit {
    width: 100%;
    margin-bottom: 30px;
    h3 {
        font-size: 26px;
        color: #000;
        line-height: 180%;
        font-weight: bold;
        text-align: center;
        i {
            font-style: normal;
            color: #c40000;
        }
    }
}
.i_box9 {
    padding: 40px 0;
    .i_box9_content {
        width: 103%;
        .list {
            width: 380px;
            float: left;
            margin-right: 30px;
            border: 1px solid #ccc;
            border-top: 2px solid #c40000;
            .tit {
                p {
                    width: 100%;
                    padding: 0 3%;
                    height: 60px;
                    border-bottom: 1px solid #ccc;
                    span {
                        font-size: 20px;
                        color: #c40000;
                        font-weight: bold;
                        float: left;
                        line-height: 60px;
                    }
                    a {
                        float: right;
                        font-size: 14px;
                        color: #666;
                        line-height: 60px;
                    }
                }
                img {
                    width: 90%;
                    height: 140px;
                    margin: 0 auto;
                    display: block;
                    margin-top: 15px;
                }
            }
            .text {
                width: 100%;
                padding: 0 5%;
                padding-bottom: 10px;
                ul {
                    li {
                        border-bottom: 1px dotted #ccc;
                        height: 32px;
                        margin-top: 5px;
                        a {
                            width: 70%;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            float: left;
                            padding-left: 20px;
                            line-height: 32px;
                            font-size: 14px;
                            color: #5a5a5a;
                            background: url(../img/hua.png) no-repeat left center;
                            &:hover {
                                color: #c40000;
                            }
                        }
                        span {
                            line-height: 32px;
                            font-size: 13px;
                            color: #999;
                            float: right;
                        }
                    }
                }
            }
            .text2 {
                width: 100%;
                padding: 0 5%;
                padding-bottom: 10px;
                ul {
                    li {
                        width: 100%;
                        margin-top: 12px;
                        padding-bottom: 12px;
                        border-bottom: 1px dotted #ccc;
                        a {
                            font-size: 14px;
                            color: #999999;
                            font-weight: bold;
                            text-decoration: none;
                            line-height: 140%;
                            padding-left: 40px;
                            background: url(../img/q.png) no-repeat left center;
                            background-size: 20px 20px;
                            &:hover {
                                color: #c40000;
                            }
                        }
                        p {    
                            margin-top: 5px;
                            font-size: 12px;
                            padding-top: 4px;
                            color: #434343;
                            font-weight: normal;
                            text-decoration: none;
                            padding-left: 40px;
                            background: url(../img/a.png) no-repeat left center;
                            background-size: 20px 20px;
                        }
                    }
                }
            }
        }
    }
    .m_list {
        width: 100%;
        display: none;
        .m_tit {
            width: 100%;
            text-align: center;
            a {    
                width: 100px;
                line-height: 42px;
                text-align: center;
                color: #c40000;
                font-size: 14px;
                border-radius: 3px;
                margin: 0 5px;
                border: 1px solid #c40000;
                @include inline-block();
            }
        }
        .m_text {
            width: 100%;
            padding: 0 1%;
            margin-top: 20px;
            ul {
                li {
                    width: 100%;
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px dotted #ccc;
                    a {
                        font-size: 14px;
                        color: #999999;
                        font-weight: bold;
                        text-decoration: none;
                        line-height: 140%;
                        display: block;
                    }
                    span {
                        display: block;
                        font-size: 14px;
                        color: #999;
                        margin-top: 8px;
                    }
                    p {
                        margin-top: 8px;
                        font-size: 14px;
                        color: #434343;
                        font-weight: normal;
                        text-decoration: none;
                        display: block;
                    }
                }
            }
        }
    }
}

.footer {
    width: 100%;
    background: #202020;
    padding: 30px 0;
    overflow: hidden;
    .foot_contact {
        float: left;
        width: 28%;
        h3 {
            font-size: 16px;
            color: #fff;
            line-height: 140%;
        }
        span {
            font-size: 12px;
            color: #999;
            margin-top: 20px;
            display: block;
            margin-bottom: 10px;
            strong {
                font-size: 20px;
                color: #c40000;
                font-weight: bold;
            }
        }
        p {
            display: block;
            line-height: 24px;
            font-size: 12px;
            color: #fff;
            margin-top: 2px;
            span {
                color: #d95351;
            }
        }
    }
    .foot_ewm {
        float: left;
        padding-right: 20px;
        padding-top: 10px;
        border-left: 1px solid #999;
        margin-left: 40px;
        padding-left: 5%;
        p {
            font-size: 16px;
            color: #fff;
            line-height: 140%;
            margin-bottom: 20px;
            text-align: center;
        }
        .ewm_text {
            float: left;
            margin-right: 20px;
            img {
                width: 126px;
                border-radius: 5px;
            }
            span {
                display: block;
                text-align: center;
                font-size: 14px;
                color: #fff;
                margin-top: 8px;
            }
        }
    }
    .foot_nav {
        float: left;
        padding-right: 20px;
        padding-top: 10px;
        border-left: 1px solid #999;
        margin-left: 40px;
        padding-left: 40px;
        width: 30%;
        ul {
            float: left;
            margin: 0 15px;
            h3 {
                font-size: 16px;
                color: #fff;
                line-height: 140%;
                margin-bottom: 20px;
                text-align: left;
            }
            li {
                float: left;
                width: 33.33%;
                a {
                    font-size: 14x;
                    color: #fff;
                    line-height: 140%;
                    margin-bottom: 20px;
                    text-align: center;
                    float: left;
                    &:hover {
                        color: #01694a;
                    }
                }
            }
        }
    }
}
.copyright {
    background: #111;
    line-height: 50px;
    color: #fff;
    font-size: 14px;
}

.page_banner {
    width: 100%;
    overflow: hidden;
    img {
        width: 1920px;
        height: auto;
        position: relative;
        left: 50%;
        margin-left: -960px;
        z-index: 8;
    }
}

.curson {
    width: 100%;
    background: #F6F6F6;
    height: 50px;
    overflow: hidden;
    p {
        width: 100%;
        margin-left: 240px;
        padding-left: 40px;
        line-height: 50px;
        background: url(../img/home.png) no-repeat left center;
        a {
            font-size: 14px;
            color: #434343;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    span {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #000;
        line-height: 50px;
        display: none;
    }
}
.wrap {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 10px;
}
.main_l {
    width: 220px;
    background: #c40000;
    position: relative;
    top: -172px;
    z-index: 9;
    h3 {
        font-size: 24px;
        color: #fff;
        font-family: arial;
        line-height: 140%;
        display: block;
        padding-top: 30px;
        padding-left: 30px;
    }
    i {
        width: 50px;
        height: 2px;
        background: #fff;
        display: block;
        margin: 10px 0;
        margin-left: 30px;
    }
    span {
        font-size: 20px;
        color: #fff;
        font-family: arial;
        line-height: 140%;
        display: block;
        padding-left: 30px;
        padding-bottom: 30px;
    }
    .page_menu {
        width: 100%;
        background: #eee;
        padding: 10px 30px;
        padding-bottom: 20px;
        li {
            width: 100%;
            display: block;
            height: 40px;
            margin-top: 16px;
            border: 1px solid #ccc;
            a {
                display: block;
                text-align: center;
                line-height: 38px;
                color: #333;
                background: #fff;
                &:hover {
                    background: #c40000;
                    color: #fff;
                }
            }
        }
    }
    .pro_menu {
        width: 98%;
        margin: 1%;
        background: #fff;
        padding: 15px;
        ul {
            width: 100%;
            li {
                padding: 0 10px;
                padding-bottom: 10px;
                border-top: 1px solid #ddd;
                &:first-child {
                    border-top: none;
                }
                .yia {
                    height: 40px;
                    a {    
                        line-height: 40px;
                        display: block;
                        text-align: left;
                        font-size: 15px;
                        color: #000;
                        font-weight: bold;
                        background: url(../img/ri.png) no-repeat right center;
                        &:hover {
                            color: #c40000;
                        }
                    }
                }
                .era {
                    a {
                        display: block;
                        line-height: 30px;
                        font-size: 15px;
                        color: #5a5a5a;
                        &:hover {
                            color: #c40000;
                        }
                    }
                }
            }
        }
    }
}
.main_r {
    width: 960px;
}
.page_content {
    width: 100%;
    border: 1px solid #eee;
    padding: 30px;
}
.page_tit {
    width: 100%;
    border-bottom: 1px solid #eee;
    line-height: 50px;
    h3 {
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: #000;
        text-align: left;
    }
}
.content {
    width: 100%;
    margin-top: 15px;
    p {
        font-size: 15px;
        color: #000;
        line-height: 28px;
        margin-bottom: 8px;
    }
    img {
        max-width: 100%;
        display: block;
        margin-bottom: 10px;
    }
}
.pro_list {
    width: 100%;
    overflow: hidden;
    ul {
        width: 104%;
        li {
            width: 304px;
            height: 328px;
            float: left;
            margin-right: 24px;
            border: 1px solid #ddd;
            margin-bottom: 24px;
            padding: 15px;
            position: relative;
            .pic {
                .imgauto {
                    width: 100%;
                    height: 272px;
                    overflow: hidden;
                    img {
                        transition: .5s;
                        transform: scale(1);
                    }
                }
                span {
                    font-size: 14px;
                    color: #5a5a5a;
                    font-weight: bold;
                    display: block;
                    text-align: center;
                    line-height: 30px;
                }
            }
            .text {
                width: 272px;
                height: 272px;
                display: none;
                background: rgba(196, 0, 0, .7);
                position: absolute;
                top: 15px;
                left: 15px;
                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    img {
                        display: block;
                        margin: 100px auto;
                    }
                }
            }
            &:hover {
                .text {
                    display: block;
                }
                .pic {
                    .imgauto {
                        img {
                            transform: scale(1.15, 1.15);
                            transition: .5s;
                        }
                    }
                    span {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

.m_promenu {
    width: 100%;
    padding: 0 1%;
    padding-bottom: 10px;
    display: none;
    .mpromenu {
        width: 100%;
        h3 {
            width: 100%;
            height: 50px;
            float: left;
            border-bottom: 1px solid #c40000;
            em {
                font-size: 16px;
                color: #c40000;
                line-height: 50px;
                float: right;
                width: 20%;
                text-align: right;
            }
            a {
                width: 80%;
                float: left;
                font-size: 14px;
                color: #c40000;
                line-height: 50px;
            }
        }
        ul {
            padding: 0px 25px;
            display: none;
            li {
                height: 30px;
                float: left;
                width: 100%;
                border-bottom: 1px solid #ccc;
                a {
                    line-height: 30px;
                    font-size: 12px;
                    color: #333;
                }
            }
        }
    }
}
.m_pagemenu {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: none;
    a {
        width: 100px;
        line-height: 36px;
        text-align: center;
        color: #c40000;
        font-size: 14px;
        border-radius: 3px;
        margin: 0 5px;
        border: 1px solid #c40000;
        @include inline-block();
        margin-bottom: 10px;
    }
}
.case_list {
    width: 100%;
    overflow: hidden;
    ul {
        width: 104%;
        li {
            width: 30%;
            height: auto;
            float: left;
            margin: 1.2%;
            position: relative;
            .pic {
                .imgauto {
                    width: 100%;
                    height: 235px;
                    overflow: hidden;
                    img {
                        transition: .5s;
                        transform: scale(1);
                    }
                }
                span {
                    font-size: 14px;
                    color: #5a5a5a;
                    font-weight: bold;
                    display: block;
                    text-align: center;
                    line-height: 30px;
                }
            }
            .text {
                width: 100%;
                height: 235px;
                display: none;
                background: rgba(196, 0, 0, 1);
                position: absolute;
                top: 0;
                left: 0;
                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    img {
                        display: block;
                        margin: 100px auto;
                    }
                }
            }
            &:hover {
                .text {
                    display: block;
                }
                .pic {
                    .imgauto {
                        img {
                            transform: scale(1.15, 1.15);
                            transition: .5s;
                        }
                    }
                    span {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
.art_list {
    width: 100%;
    ul {
        li {
            width: 100%;
            padding: 25px;
            border: 1px solid #eee;
            float: left;
            margin-bottom: 20px;
            .fl {
                width: 26.5%;
                img {
                    max-width: 100%;
                }
            }
            .fr {
                width: 70%;
                a {
                    font-size: 18px;
                    color: #434343;
                    font-weight: bold;
                    display: block;
                    padding-top: 8px;
                    &:hover {
                        color: #c40000;
                    }
                }
                span {
                    display: block;
                    font-size: 14px;
                    color: #929292;
                    padding-top: 10px;
                }
                p {
                    font-size: 14px;
                    color: #555;
                    line-height: 140%;
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
}






div.zoomDiv{z-index:999;position:absolute;top:0;left:0;background:#ffffff;border:1px solid #ddd;display:none;text-align:center;overflow:hidden;font-size: 0;}
div.zoomMask{position:absolute;background:url(../img/mask.png) repeat scroll 0 0 transparent;cursor:move;z-index:1;}


.yunu-showcase {
    margin-bottom: 30px;
    .yunupor {
        width: 45%;
        float: left;
    }
    .yunu-showcase-pic {
        position: relative;
        width: 100%;
        .cover {
            width: 405px;
            height: 360px;
            line-height: 400px;
            border: 1px solid #ddd;
            padding: 1px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
    }
    .prev {
        background: url(../img/prole.png) no-repeat 0 0;
        left: 0;
        position: absolute;
        width: 14px;
        height: 27px;
        bottom: 26px;
        z-index: 2;
        &:hover {
            background-position: 0 bottom;
        }
    }
    .next {
        background: url(../img/prori.png) no-repeat 0 0;
        right: 0;
        position: absolute;
        width: 14px;
        height: 27px;
        bottom: 26px;
        z-index: 2;
        &:hover {
            background-position: 0 bottom;
        }
    }
    .showpropic {
        width: 405px;
        padding: 0 33px;
        position: absolute;
        bottom: 0;
        right: -405px;
        .bd {
            font-size: 0;
            margin-top: 13px;
            overflow: hidden;
            .picList {
                li {
                    height: 75px;
                    line-height: 75px;
                    cursor: pointer;
                    display: inline-block;
                    width: 99px;
                    border: 1px solid #ddd;
                    padding: 1px;
                    margin: 0 8px;
                    overflow: hidden;
                    &.active {
                        border-color: #c40000;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
.pro_desc {
    width: 55%;
    float: left;
    padding-top: 80px;
    h3 {
        font-size: 20px;
        color: #434343;
        font-weight: bold;
        display: block;
        text-align: left;
        margin-top: 5px;
    }
    p {
        font-size: 14px;
        color: #666;
        line-height: 140%;
        margin-top: 10px;
    }
}

.pro_content {
    .pro_cont1 {
        .hd {
            border: 1px solid #eee;
            position: absolute;
            width: 530px;
            top: 0;
            right: 0;
            ul {
                li {
                    width: 20%;
                    float: left;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 14px;
                    color: #333;
                    border-left: 1px solid #eee;
                    &:first-child {
                        border: none;
                    }
                    &.on {
                        background: #c40000;
                        color: #fff;
                    }
                }
            }
        }
        .bd {
            width: 100%;
            margin-top: 15px;
            ul {
                .text {
                    p {
                        font-size: 15px;
                        color: #000;
                        line-height: 28px;
                        margin-bottom: 8px;
                    }
                    img {
                        max-width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}
.page_content {
    .case_tit {
        h3 {
            color: #c40000;
            text-align: center;
        }
    }
}
.page_content {
    .art_tit {
        width: 100%;
        line-height: 50px;
        h3 {
            text-align: center;
            display: block;
            font-size: 18px;
            font-weight: bold;
            color: #000;
        }
        p {
            display: block;
            text-align: center;
            width: 100%;
            line-height: 24px;
            span {
                @include inline-block();
                font-size: 12px;
                color: #5a5a5a;
                margin: 0 10px;
            }
        }
    }
}
.art_desc {
    width: 100%;
    background: #f5f5f5;
    padding: 5px;
    border: 1px solid #e5e5e5;
    margin-top: 20px;
    p {
        font-size: 12px;
        line-height: 20px;
        color: #5a5a5a;
    }
}
.contact1 {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
    ul {
        li {
            width: 25%;
            float: left;
            border-right: 1px solid #eee;
            &:first-child {
                border-left: 1px solid #eee;
            } 
            img {
                display: block;
                margin: 0 auto;
                width: 80px;
                height: 80px;
                max-width: 100%;
            }
            span {
                display: block;
                text-align: center;
                font-size: 18px;
                color: #333;
                font-weight: bold;
                margin-top: 20px;
            }
            p {
                display: block;
                text-align: center;
                font-size: 14px;
                color: #666;
                width: 70%;
                margin: 0 auto;
                margin-top: 20px;
            }
        }
    }
}

.fuwu1 {
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 50px;
    .fl {
        width: 46%;
        background: #FF0000;
        padding: 60px;
        position: relative;
        margin-top: -270px;
        z-index: 9;
        .text {
            width: 100%;
            strong {
                font-size: 30px;
                color: #fff;
                line-height: 140%;
                display: block;
                font-weight: normal;
                em {
                    font-weight: bold;
                    margin: 0 3px;
                    font-style: normal;
                }
            }
            ul {
                width: 100%;
                border-top: 1px solid #fff;
                margin-top: 30px;
                padding-top: 30px;
                li {
                    width: 100%;
                    float: left;
                    margin-bottom: 30px;
                    img {
                        float: left;
                        width: 16%;
                    }
                    p {
                        float: left;
                        width: 82%;
                        margin-left: 2%;
                        span {
                            display: block;
                            font-size: 20px;
                            color: #fff;
                            line-height: 140%;
                            display: block;
                            font-weight: bold;
                            margin-top: 8px;
                        }
                        i {
                            display: block;
                            font-style: normal;
                            font-size: 14px;
                            color: #fff;
                            display: block;
                            line-height: 140%;
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
    .fr {
        width: 46%;
        margin-top: 40px;
        span {
            display: block;
            font-size: 28px;
            color: #000;
            line-height: 140%;
            margin-bottom: 30px;
        }
        p {
            font-size: 14px;
            color: #666;
            display: block;
            line-height: 200%;
            margin-top: 10px;
        }
    }
}


.fuwu2 {
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 50px;
    .fl {
        width: 45%;
        h3 {
            font-size: 28px;
            color: #000;
            display: block;
            line-height: 140%;
            padding-bottom: 30px;
            border-bottom: 1px solid #ddd;
            margin-top: 15px;
        }
        span {
            font-size: 20px;
            font-weight: bold;
            display: block;
            line-height: 140%;
            color: #FF0000;
            margin-top: 30px;
        }
        p {
            font-size: 14px;
            line-height: 180%;
            color: #666;
            display: block;
            margin-top: 15px;
        }
    }
    .fr {
        width: 46%;
        img {
            max-width: 100%;
        }
    }
}

.fuwu3 {
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-bottom: 50px;
    .fl {
        width: 45%;
        span {
            font-size: 28px;
            display: block;
            line-height: 140%;
            color: #000;
            margin-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #ddd;
        }
        img {
            max-width: 100%;
            display: block;
            margin-top: 40px;
        }
    }
    .fr {
        width: 46%;
        span {
            font-size: 28px;
            display: block;
            line-height: 140%;
            color: #000;
            margin-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #ddd;
        }
        .pic {
            width: 100%;
            margin-top: 40px;
            img {
                max-width: 100%;
                float: left;
                width: 48%;
                margin: 0 1%;
                margin-bottom: 10px;
            }
        }
    }
}

















.show_pages {
    width: 100%;
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
    p {
        font-size: 14px;
        color: #666;
        line-height: 24px;
        a {
            font-size: 14px;
            color: #666;
            line-height: 24px;
            &:hover {
                color: #c40000;
            }
        }
    }
}

.pro_cntent {
    .show_tit {
        width: 100%;
        margin: 15px 0;
        padding-bottom: 15px;
        border-bottom: 1px solid #ccc;
    }
}
.show_pic {
    width: 100%;
    img {
        max-width: 100%;
        display: block;
    }
}






































































.m_foot{
    background: #111;
    padding: 20px 0;
    display: none;
    p {
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 24px;
    }
}

.iet {
    height: 30px;
    width: 100%;
    background: #fff3d7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #333;
        line-height: 30px;
        a {
            color: #3aba50;
            font-size: 12px;
        }
    }
    span {
        position: absolute;
        right: 5%;
        top: 0;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
        i {
            font-size: 12px;
            color: #333;
            padding-left: 5px;
            font-style: normal;
        }
    }
}







@media screen and (max-width: 1200px){
    .center {
        width: 100%;
    }
    .i_box1_content .fl {
        display: none;
    }
    .i_box1_content .fr {
        width: 100%;
        padding: 2%;
    }
    .i_box1_content .fr .list ul {
        width: 100%;
    }
    .box1_pro_tit {
        display: block;
    }
    .i_box1_content .fr .list ul li {
        width: 33.33%;
        margin-right: 0;
    }
    .i_box2 .text {
        margin-left: 20px;
    }
    .i_box5_content .fl {
        width: 48%;
    }
    .i_box5_content .fr {
        width: 48%;
    }
    .i_box7_content .fl {
        display: none;
    }
    .i_box7_content .fr {
        width: 100%;
        padding: 0 1%;
    }
    .i_box7_content .fr ul {
        width: 100%;
    }
    .i_box7_content .fr ul li {
        width: 33.33%;
        margin-right: 0;
    }
    .i_box7_content .fr ul li .imgauto {
        display: block;
        margin: 0 auto;
    }
    .i_box9 .i_box9_content .list {
        margin-bottom: 40px;
        margin-left: 10px;
    }
    .i_box9 .i_box9_content {
        width: 100%;
    }
    .page_banner img {
        width: 100%;
        height: auto;
        left: 0;
        margin-left: 0;
    }
    .m_promenu {
        display: block;
    }
    .main_l {
        display: none;
    }
    .main_r {
        width: 100%;
        padding: 0 1%;
    }
    .pro_list ul li {
        width: 30%;
        height: auto;
        margin-right: 3.08%;
    }
    .pro_list ul li .text {
        display: none!important;
    }
    .curson p {
        margin-left: 10px;
    }
    .m_pagemenu {
        display: block;
    }
    .case_list ul li .text {
        display: none!important;
    }
    .fuwu1 .fl {
        margin-top: -160px;
    }
    .fuwu1 {
        width: 100%;
        padding: 0 1%;
        padding-bottom: 40px;
    }
    .fuwu2 {
        width: 100%;
        padding: 0 1%;
        padding-bottom: 40px;
    }
    .fuwu3 {
        width: 100%;
        padding: 0 1%;
        padding-bottom: 40px;
    }
    .pro_content .pro_cont1 .hd {
        position: relative;
        width: 100%;
    }
    .yunu-showcase .showpropic {
        position: relative;
        right: 0;
    }
    .pro_desc {
        padding-top: 0;
    }
}

@media screen and (max-width: 1020px){
    .m_header {
        display: block;
    }
    .header {
        display: none;
    }
    .m_header .m_head_content {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo img {
        max-height: 100%;
    }
    .m_header .m_head_content .menu {
        height: 80px;
        width: 80px;
    }
    .m_header .m_head_content .menu i {
        height: 80px;
        line-height: 80px;
    }
    .app_menu {
        top: 80px;
    }
    .app_menu ul li a span {
        font-size: 14px;
        height: 42px;
        line-height: 42px;
    }
    .mtbanner {
        padding-top: 80px;
    }
    .page_banner {
        padding-top: 80px;
    }
    .pc_banner {
        display: none;
    }
    .m_banner {
        display: block;
    }
    .i_box2 {
        background: url(../img/bann.jpg) no-repeat left;
    }
    .i_box5_content .fr ul li {
        margin-right: 12px;
    }

    .i_box7_content .fr ul li .imgauto {
        display: block;
        margin: 0 auto;
        width: 96%;
    }
    .footer .foot_nav {
        display: none;
    }
    .footer {
        padding-left: 10px;
    }
    .copyright {
        padding-left: 10px;
    }
    .yunu-showcase .yunupor {
        width: 405px;
        margin: 0 auto;
        float: none;
    }
    .pro_desc {
        width: 100%;
        float: none;
        margin-top: 20px;
    }
}




@media screen and (max-width: 850px) {
    .search .text .fl{
        width: 98%;
        margin: 0 1%;
    }
    .search .text .fr {
        width: 98%;
        margin: 0 1%;
    }
    .search .text .fr .btn1 {
        width: 80%;
    }
    .search .text .fr .btn2 {
        width: 19%;
        text-align: center;
    }
    .box3_list .item .pic {
        width: 100%;
    }
    .box3_list .item .text {
        width: 100%;
        padding: 0 5%;
    }
    .box3_list .item .pic {
        display: none;
    }
    .box3_list .item .mpic {
        display: block;
        margin-top: 10px;
        float: left;
        width: 100%;
    }
    .box3_list .item2 {
        padding-left: 0;
    }
    .box3_list .item .mpic img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        margin-top: 10px;
    }
    .box3_tit p i {
        font-size: 40px;
        font-style: normal;
    }
    .box3_tit p span {
        font-size: 40px;
    }
    .i_box5_content .fl {
        width: 100%;
    }
    .i_box5_content .fr {
        width: 100%;
        padding: 0 1%;
    }
    .i_box6 ul li {
        width: 23%;
        margin: 0 1%;
    }
    .i_box6 ul li span i {
        font-size: 28px;
    }
    .i_box8_content .box8left ul li {
        width: 33.33%;
    }
    .i_box9 .i_box9_content .list {
        display: none;
    }
    .i_box9 .m_list {
        display: block;
    }
    .footer .foot_contact {
        width: 35%;
    }
    .box4_tit span {
        display: none;
    }
    .pro_list ul li .pic .imgauto {
        height: 210px;
    }
    .page_content {
        border: none;
        padding: 0 2%;
    }
    .page_tit h3 {
        text-align: center;
    }

    .case_list ul {
        width: 100%;
    }
    .case_list ul li {
        width: 48%;
        margin: 1%;
    } 
    .case_list ul li .pic .imgauto {
        height: 240px;
    }
    .art_list ul li .fl {
        width: 41%;
    }
    .art_list ul li .fr {
        width: 56%;
    }
    .art_list ul li .fr a {
        padding-top: 3px;
    }
    .art_list ul li .fr span {
        padding-top: 6px;
    }
    .art_list ul li .fr p {
        margin-top: 6px;
    }
    .contact1 ul li p {
        width: 90%;
    }
    .fuwu1 {
        padding: 0;
        padding-bottom: 30px;
    }
    .fuwu1 .fl {
        margin-top: 0;
        width: 100%;
        padding: 50px;
    } 
    .fuwu1 .fr {
        margin-top: 20px;
        width: 100%;
        padding: 0 1%;
    } 
    .fuwu2 .fl {
        margin-top: 0;
        width: 100%;
    } 
    .fuwu2 .fr {
        margin-top: 20px;
        width: 100%;
    } 
    .fuwu3 .fl {
        margin-top: 0;
        width: 100%;
    } 
    .fuwu3 .fr {
        margin-top: 20px;
        width: 100%;
    } 
}



@media screen and (max-width: 585px){
    .search .text .fl {
        display: none;
    }
    .box1_pro_tit ul li {
        width: 25%;
        float: left;
    }
    .box1_pro_tit ul li a {
        width: 95%;
    }
    .i_box5_content .fr h3 {
        margin-bottom: 10px;
    }
    .i_box5_content .fr ul li {
        width: 20%;
        margin-right: 0;
    }
    .i_box5_content .fr ul li img {
        max-width: 95%;
        height: auto;
        margin: 0 auto;
        display: block;
    }
    .m_header  {
        .m_head_content {
            height: .46rem;
            .m_head_logo {
                height: .4rem;
                img {
                    margin-top: .03rem;
                }
            }
            .menu {
                width: .46rem;
                height: .46rem;
                i {
                    height: .46rem;
                    line-height: .46rem;
                }
            }
        }
    }
    .app_menu {
        top: .46rem;
    }
    .mtbanner {
        padding-top: .46rem;
    }
    .page_banner {
        padding-top: .46rem;
    }
    .i_box1_content .fr {
        padding: 1%;
        border: none;
    }
    .i_box1_content .fr .list ul li {
        width: 48%;
        margin: 1%;
    }
    .i_box1_content .fr .list ul li .pic .imgauto {
        height: 200px;
    }
    .i_box2 .text {
        width: 100%;
        padding: 2%;
        padding-top: 40px;
        margin-left: 0;
    }
    .i_box2 .text strong {
        border: none;
        width: 100%;
        font-size: 24px;
        letter-spacing: 0px;
        height: auto;
    }
    .i_box2 .text p a {
        float: none;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
    }
    .box3_list .item {
        width: 100%;
    }
    .box3_tit p {
        font-size: 24px;
        margin-top: 10px;
    }
    .box3_tit p i {
        font-size: 24px;
    }
    .box3_tit p span {
        font-size: 24px;
    }
    .box3_list .item .text .text_tit {
        background: none;
    }
    .box3_list .item .text .text_tit em {
        float: none;
        margin: 0 auto;
    }
    .box3_list .item .text .text_tit p {
        padding-left: 0;
        text-align: center;
        padding-top: 10px;
    }
    .box3_list .item .text {
        padding: 0 2%;
    }
    .box3_list .item .text .text_text {
        margin-top: 20px;
    }
    .i_box6 {
        padding: 20px 0;
    }
    .i_box6 ul li {
        width: 100%;
        margin-bottom: 15px;
    }
    .i_box6 ul li p {
        display: none;
    }
    .i_box7_content .fr ul li {
        width: 48%;
        margin: 0 1%;
    }
    .i_box7_content .fr ul li .imgauto {
        height: 165px;
    }
    .i_box7_content .fr ul li .text {
        position: relative;
        display: block;
        height: 24px;
        background: #fff;
    }
    .i_box7_content .fr ul li .text a {
        line-height: 24px;
        color: #333;
    }
    .i_box7_content .fr h3 {
        display: block;
        margin-bottom: 15px;
    }
    .i_box7 {
        margin-top: 10px;
    }
    
    .i_box7 .box4_tit {
        display: none;
    }
    .i_box9 .m_list .m_tit a {
        float: left;
        width: 23%;
        margin: 0 1%;
    }
    .footer .foot_contact {
        width: 100%;
    }
    .footer .foot_ewm p {
        display: none;
    }
    .footer .foot_ewm {
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        border: none;
        width: 100%;
        padding-right: 0;
    }
    .copyright {
        line-height: 24px;
        padding: 10px 0;
        text-align: center;
    }
    .pro_list ul {
        width: 100%;
    }
    .pro_list ul li {
        width: 48%;
        margin: 1%;
    }
    .wrap {
        margin-top: 10px;
    }
    .curson p {
        display: none;
    }
    .curson span {
        display: block;
    }
    .m_pagemenu {
        text-align: left;
    }
    .m_pagemenu a {
        width: 22%;
        margin: 1%;
    }
    .case_list ul li .pic .imgauto {
        height: 168px;
    }
    .art_list ul li {
        padding: 1%;
        border: none;
    }
    .art_list ul li .fl {
        width: 100%;
    }
    .art_list ul li .fr {
        width: 100%;
    }
    .contact1 ul li {
        width: 50%;
        margin-bottom: 30px;
    }
    .fuwu1 .fl {
        margin-top: 0;
        width: 100%;
        padding: 10px;
    } 
}


@media screen and (max-width: 450px) {
    .search {
        padding: 10px 0;
    }
    .search .text .fr .btn1 {
        width: 80%;
    } 
    .search .text .fr .btn2 {
        width: 14%;
    } 
    .i_box1 {
        padding-top: 0px;
    }
    .box1_pro_tit ul li a {
        line-height: 36px;
        border-radius: 3px;
        margin: 0 auto;
    }
    .i_box2 .text {
        padding-top: 30px;
    }
    .i_box2 .text p {
        text-align: center;
        letter-spacing: 0px;
    }
    .i_box2 .text strong {
        font-size: 20px;
    }
    .i_box2 .text p span {
        font-size: 18px;
    }
    .i_box5_content .fl .mbox5pic .next {
        top: 40%;
    }
    .i_box5_content .fl .mbox5pic .prev {
        top: 40%;
    }
    .i_box7_content .fr ul li {
        margin-bottom: 10px;
    }
    .i_box7_content .fr ul li .imgauto {
        height: 120px;
    }
    .i_box7_content .fr ul li .text {
        position: relative;
        display: block;
        height: 24px;
        background: #fff;
    }
    .i_box7_content .fr ul li .text a {
        line-height: 24px;
        color: #333;
    }
    .footer .foot_contact h3 {
        text-align: center;
    }
    .footer .foot_contact span {
        text-align: center;
    }
    .footer .foot_contact p {
        text-align: center;
    }
    .footer .foot_ewm {
        text-align: center;
    }
    .footer .foot_ewm .ewm_text {
        float: none;
        @include inline-block();
    }
    .case_list ul li {
        width: 98%;
    }
    .case_list ul li .pic .imgauto {
        height: 250px;
    }
    .yunu-showcase .yunupor {
        width: 100%;
    }
    .yunu-showcase .yunu-showcase-pic .cover {
        width: 100%;
    }
    .yunu-showcase .showpropic {
        width: 100%;
    }
    .yunu-showcase .showpropic .bd .picList li {
        width: 30%;
    }
    .contact1 ul li {
        width: 98%;
        margin: 0 1%;
        border: none;
        margin-bottom: 30px;
    }

    .fuwu1 .fl .text strong {
        font-size: 24px;
        text-align: center;
    }
    .fuwu1 .fl .text ul {
        padding-top: 15px;
        margin-top: 15px;
    }
}

@media screen and (max-width: 400px) {
    .pro_list ul li .pic .imgauto {
        height: 150px;
    }
    .fuwu3 .fr .pic img {
        width: 98%;
    }
    
}

/****************************** media ******************************/