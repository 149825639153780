@for $i from 1 through 10 {
    $location: (ml: margin-left, mt: margin-top, mr: margin-right, mb: margin-bottom, mg: margin, pl: padding-left, pt: padding-top, pr: padding-right, pb: padding-bottom, pd: padding);
    @each $a,
    $b in $location {
        .#{$a}#{5 * $i} {
            #{$b}: .05rem * $i!important;
        }
    }
}

@for $i from 6 through 18 {
    .f#{2 * $i} {
        font-size: .02rem * $i;
    }
}

@for $i from 10 through 20 {
    .lh#{2 * $i} {
        line-height: .02rem * $i;
    }
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clear {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.clearfix:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.container {
    margin-right: auto;
    margin-left: auto;
}

.container:after,
.row:after,
.center,
.fl_box,
ul {
    @extend .clearfix;
}

.fl_box {
    > * {
        float: left;
    }
}

.content img {
    max-width: 100%;
}

.content_box {
    &,
    *,
    *:before,
    *:after {
        box-sizing: content-box;
    }
}

.tal {
    text-align: left;
}

.tac {
    text-align: center;
}

.tar {
    text-align: right;
}

.vat {
    vertical-align: top;
}

.vam {
    vertical-align: middle;
}

.vab {
    vertical-align: bottom;
}

.block {
    display: block;
}

.iblock {
    display: inline-block;
}

.posr {
    position: relative;
}

.posa {
    position: absolute;
}

.posf {
    position: fixed;
}

.cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.contain {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* start-----新闻详情-----start */

.introduction {
    margin-bottom: .2rem;
    border: 1px solid #e5e5e5;
    padding: .05rem;
    background-color: #f5f5f5;
    color: #868686;
    line-height: .22rem;
}

.news_title {
    text-align: center;
    margin-top: .3rem;
    h1 {
        font-size: .16rem;
        color: #434343;
        font-weight: bold;
    }
    p {
        padding-top: .1rem;
        span {
            padding: 0 .03rem;
        }
    }
}
/* end-----新闻详情-----end */

/* loadmore-----列表分页-----loadmore */
@keyframes ball-fall {
    0% {
        opacity: 0;
        transform: translateY(-145%);
    }
    10% {
        opacity: .5;
    }
    20% {
        opacity: 1;
        transform: translateY(0);
    }
    80% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: .5;
    }
    100% {
        opacity: 0;
        transform: translateY(145%);
    }
}
.load_more {
    position: relative;
    width: 90%;
    margin: 0 auto;
    transition: all .3s;
    .la-ball-fall {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -36px;
        margin-top: -9px;
        opacity: 0;
        visibility: hidden;
        cursor: not-allowed;
        &.la-sm {
            margin-left: -18px;
            margin-top: -4px;
        }
        &.la-2x {
            margin-left: -72px;
            margin-top: -18px;
        }
        &.la-3x {
            margin-left: -108px;
            margin-top: -27px;
        }
    }
    .load_more_btn {
        border: 0;
        color: #fff;
        text-align: center;
        width: 100%;
        line-height: 2em;
        border-radius: 2em;
        @include hover(#00a2e9,background-color);
        outline: none;
        &[disabled] {
            color: transparent;
            opacity: .6;
            cursor: not-allowed;
            ~ .la-ball-fall {
                opacity: 1;
                visibility: visible;
                transition-delay: 0s;
            }
        }
    }
}
.la-ball-fall {
    display: block;
    font-size: 0;
    color: #fff;
    &,&>div {
        position: relative;
        box-sizing: border-box;
    }
    &.la-dark {
        color: #333;
    }
    &>div {
        display: inline-block;
        float: none;
        background-color: currentColor;
        border: 0 solid currentColor;
        width: 10px;
        height: 10px;
        margin: 4px;
        border-radius: 100%;
        opacity: 0;
        animation: ball-fall 1s ease-in-out infinite;
        &:nth-child(1) {
            animation-delay: -300ms;
        }
        &:nth-child(2) {
            animation-delay: -200ms;
        }
        &:nth-child(3) {
            animation-delay: -100ms;
        }
        &:nth-child(4) {
            animation-delay: 0ms;
        }
    }
    &.la-sm>div {
        width: 5px;
        height: 5px;
        margin: 2px;
    }
    &.la-2x>div {
        width: 20px;
        height: 20px;
        margin: 8px;
    }
    &.la-3x>div {
        width: 30px;
        height: 30px;
        margin: 12px;
    }
}
/* loadmore-----列表分页-----loadmore */
